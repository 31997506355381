<script lang="ts">
    import { PageTexts, Locale } from '../../Data/Locale';
</script>

<div class="partners">
    <div class="flex flex-col flex-1">
        <h1 class="text-white text-4xl md:text-[3.25rem] font-semibold mb-6">
            {PageTexts[$Locale].partners_title}
        </h1>
        <h2 class="text-white text-lg mb-8 max-w-[70ch] text-center">
            {PageTexts[$Locale].partners_desc}
        </h2>
    </div>
    <div class="partner-container">
        <div class="partner-frame">
            <a href="https://passon.io">
                <img src="images/tokens/passon.png" alt="Passon" />
            </a>
        </div>
        <div class="partner-frame">
            <a href="https://pulsechain.com/">
                <img src="images/tokens/pulsechain.png" alt="PulseChain" />
            </a>
        </div>
        <div class="partner-frame">
            <a href="https://rbxdefi.com">
                <img src="images/tokens/rbx.png" alt="RBX" />
            </a>
        </div>
        <div class="partner-frame text-4xl max-w-[3rem] flex">
            <div class="self-center text-white italic font w-full">
                {PageTexts[$Locale].and_more}
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    .partner-container {
        @apply grid grid-cols-2 md:grid-cols-4 gap-16 pt-8;
    }
    .partner-frame {
        @apply w-[8em]  md:w-[12em];
    }

    .partners {
        @apply px-4 py-6 md:px-16 md:py-24 bg-sky-300 w-full flex-1 flex flex-col items-center;
    }
</style>
