<script lang="ts">
    import { IPaymentMethod, IProviderRate } from "../../Data/P2P";
    import { createEventDispatcher } from "svelte";
    import type { IChain } from "../../Data/Chains";
    import { SkipTexts, Locale } from "../../Data/Locale";

    export let selectedVenue: IPaymentMethod;
    export let paymentMethodsMap: { [key: string]: IChain & { specialVenueIDName?: string, idInputFields: string[] } };
    export let infoText: string = '6b7280';
    export let rampRate: IProviderRate;
    export let selectedMenuItem: 'BUY' | 'SELL';
    export let venueId: string;

    const dispatch = createEventDispatcher();

    const onIdInput = (val: string) => {
        dispatch('idInput', {val: val});
    }
    const onIdInputSegmented = (val: string, i: number, total: number) => {
        dispatch('idInputSegmented', {val: val, i: i, total: total});
    }
    const onKYPInput = (val: string, i: number) => {
        dispatch('kypInput', {val: val, i: i});
    }
</script>


<div id="_pay_ID_input" class="flex py-1">
    {#if selectedVenue?.shortName && paymentMethodsMap[selectedVenue.shortName].idInputFields.length > 0}
        <span class="pl-2" style="color: #{infoText}"
            >{selectedVenue ? selectedVenue.shortName + ' ID:' : SkipTexts[$Locale].payment_venue_id}</span>
        <div
            data-tooltip={`Enter the ${selectedVenue.shortName} ID that you will be using ${
                selectedMenuItem == 'BUY' ? 'to send fiat' : 'to receive fiat'
            } for this trade.`}
            class="flex flex-col"><!--class="flex flex-col flex-wrap" style="max-height: calc(3 * 0.9rem);"-->
            {#each paymentMethodsMap[selectedVenue.shortName].idInputFields as placeholder, i}
                <input
                    on:input={(e) => {
                        const value = e.currentTarget?.value;
                        if (placeholder.includes('#')) {
                            e.currentTarget.value = value.replace(/\D/g, '');
                        }
                        onIdInputSegmented(
                            e.currentTarget?.value,
                            i,
                            paymentMethodsMap[selectedVenue?.shortName || ''].idInputFields.length
                        );
                    }}
                    id="pay-ID-input-{i}"
                    class="underline pl-2 id-input w-[12em]"
                    style="color: #{infoText}"
                    {placeholder} />
            {/each}
        </div>
        {#if rampRate?.kypString?.length > 0}
            <div class="flex flex-col">
                {#each rampRate.kypString.split('::') as field, index}
                    <div class="flex">
                        <span class="pl-2 flex whitespace-nowrap" style="color: #{infoText}">{'KYP Info:'}</span>
                        <input
                            on:input={(e) => onKYPInput(e?.currentTarget?.value, index)}
                            id="pay-ID-input"
                            class="underline pl-2 id-input"
                            style="color: #{infoText}"
                            placeholder={field} />
                    </div>
                {/each}
            </div>
        {/if}
    {:else}
        <div class="flex">
            <div
                data-tooltip={`Enter the ${selectedVenue?.shortName || 'venue'} ID that you will be using ${
                    selectedMenuItem == 'BUY' ? 'to send fiat' : 'to receive fiat'
                } for this trade.`}
                class="flex {rampRate?.kypString?.length > 0 ? 'flex-col' : ''}">
                <span class="flex pl-2" style="color: #{infoText}"
                    >{selectedVenue ? (selectedVenue.shortName || 'venue') + ' ID:' : SkipTexts[$Locale].payment_venue_id}</span>
                <input
                    on:input={(e) => onIdInput(e.currentTarget?.value)}
                    bind:value={venueId}
                    id="pay-ID-input"
                    class="underline pl-2 id-input"
                    style="color: #{infoText}"
                    placeholder={selectedVenue?.specialVenueIDName ?? SkipTexts[$Locale].enter_venue_id} />
            </div>
            {#if rampRate?.kypString?.length > 0}
                <div class="flex flex-col">
                    {#each rampRate.kypString.split('::') as field, index}
                        <div class="flex">
                            <span class="pl-2 flex whitespace-nowrap" style="color: #{infoText}">{'KYP Info:'}</span>
                            <input
                                on:input={(e) => onKYPInput(e?.currentTarget?.value, index)}
                                id="pay-ID-input"
                                class="underline pl-2 id-input"
                                style="color: #{infoText}"
                                placeholder={field} />
                        </div>
                    {/each}
                </div>
            {/if}
        </div>
    {/if}
</div>