import axios from "axios";
import type { IP2PTrade } from "Data/P2P";

const serverURL = `${import.meta.env.VITE_SKIPSCAN_SERVER_URL}/get-trades`

export const fetchAddressTrades = async (address: string, active: boolean): Promise<IP2PTrade[] | undefined> => {
    const resp = await axios.get('https://skipscan.win/api/get-trades', {//todo: use the fucken env
        params: {
            address: address,
            active: active
        }
    });

    console.log('response data', resp.data);

    if(resp) return resp.data;
    else return;
}