<script lang='ts'>
  import IntegrateButton from "../Common/IntegrateButton.svelte";
  import TelegramButton from "../Common/TelegramButton.svelte";
  import { Accordion, AccordionItem } from 'svelte-collapsible';
  import { PageTexts, Locale, FAQTexts } from "../../Data/Locale";

  const duration = 0.2; // seconds
  const easing = 'linear';

  let key: string;
</script>
<div
class="blurb"
>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
    <div class="flex flex-col text-white md:border-r">
      <span class="text-4xl md:text-[2rem] pb-4">{PageTexts[$Locale].faq_left_title}</span>
      <span class="pt-2 max-w-[65ch]">{PageTexts[$Locale].faq_left_desc}</span>
        <span class="text-center pt-8">{PageTexts[$Locale].faq_left_sub_desc}</span>
      <div class="pt-6 flex">
        <div class="m-auto flex grow md:grow-0 gap-4">
        <IntegrateButton></IntegrateButton>
        <TelegramButton></TelegramButton>
        </div>
      </div>
    </div>
    <div class="faq-container text-white">
      <h1 class="text-2xl pb-4 md:text-[2rem] text-white">{PageTexts[$Locale].faq_right_title}</h1>
      <Accordion duration={duration} easing={easing} bind:key={key}>
        <AccordionItem key={'a'}>
          <h2 slot='header'>{FAQTexts[$Locale].what_is_title}</h2>
          <p slot='body'>{FAQTexts[$Locale].what_is_desc}</p>
        </AccordionItem>
        <AccordionItem key="{'b'}">
          <h2 slot='header'>{FAQTexts[$Locale].how_to_onramp_title}</h2>
          <p slot='body'>{@html FAQTexts[$Locale].how_to_onramp_desc}</p>
        </AccordionItem>
        <AccordionItem key="{'c'}">
          <h2 slot='header'>{FAQTexts[$Locale].how_to_offramp_title}</h2>
          <p slot='body'>{@html FAQTexts[$Locale].how_to_offramp_desc}</p>
        </AccordionItem>
        <AccordionItem key={'d'}>
          <h2 slot='header'>{FAQTexts[$Locale].collateral_requirements_title}</h2>
          <p slot='body'>{FAQTexts[$Locale].collateral_requirements_desc}</p>
        </AccordionItem>
        <AccordionItem key={'e'}>
          <h2 slot='header'>{FAQTexts[$Locale].provider_scoring_title}</h2>
          <p slot='body'>{FAQTexts[$Locale].provider_scoring_desc}</p>
        </AccordionItem>
        <AccordionItem key={'f'}>
          <h2 slot='header'>{FAQTexts[$Locale].face_issues_title}</h2>
          <p slot='body'>{@html FAQTexts[$Locale].face_issues_desc}</p>
        </AccordionItem>
        <AccordionItem key={'g'}>
          <h2 slot='header'>{FAQTexts[$Locale].skipshift_token_title}</h2>
          <p slot='body'>{FAQTexts[$Locale].skipshift_token_desc}</p>
        </AccordionItem>
      </Accordion>
    </div>
  </div>
</div>

<style lang="scss">
  .blurb{
    @apply px-4 py-6 md:px-16 md:py-24 bg-black w-full
  }
  .faq-container{
    @apply pt-6 md:pt-0;
  }
  h2{
    @apply text-sky-500 py-0.5;
    text-align: start;
  }

  p {
    padding: 0.5rem 1.5rem;
    width: 80%;
  }
</style>