<script lang="ts">
  import { paymentMethods } from "../../Data/P2P";
  import IntegrateButton from "../Common/IntegrateButton.svelte";
  import { PageTexts, Locale } from "../../Data/Locale";

  const methods = paymentMethods;
</script>

<div
class="methods px-4 py-6 md:px-16 md:py-24"
>
  <div class="flex flex-col flex-1 ">
    <h1 class="text-white text-4xl md:text-[3rem] text-center font-semibold mb-6">
      {PageTexts[$Locale].methods_title}
    </h1>
    <h2 class="text-white text-lg mb-8 max-w-[70ch] text-center">
      {PageTexts[$Locale].methods_desc}
    </h2>
  </div>
  <div class="method-holder flex flex-wrap justify-center items-center">
    {#each Object.values(methods) as method}
      <button class="method-icon">
        <!-- svelte-ignore a11y-missing-attribute -->
        <img class="rounded-lg" src="{method.logoURI}"/>
      </button>
    {/each}
  </div>
  <div class="flex flex-col pt-8 items-center">
    <span class="text-2xl text-white pb-6">{PageTexts[$Locale].available_on}</span>
    <div class="flex items-center justify-between px-2 md:max-w-full">
      <div class="chain-logo max-w-max">
        <img src="/images/long-chains/eth.png" alt="Ethereum">
      </div>
      <div class="chain-logo max-w-max">
        <img src="/images/long-chains/bsc.png" alt="Binance Smart Chain">
      </div>
      <div class="chain-logo max-w-max">
        <img src="/images/long-chains/avax.png" alt="Avalanche">
      </div>
    </div>
  </div>
  <div class="pt-6 flex">
        <div class="w-max flex grow md:grow-0 ">
          <IntegrateButton></IntegrateButton>
          </div> 
  </div>
</div>

<style lang="scss">
  .chain-logo{
    border-radius: 12px;
    background: var(--white, #FFF);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 0.25rem 1rem;
    margin: 0.5em;
  }
  .methods{
    @apply px-4 md:px-0 pt-16 md:pt-16 container mx-auto flex-1 flex flex-col items-center;
  }
  .method-holder{
    @apply max-w-[95%] md:max-w-full items-center;
  }
  .method-icon{
    @apply rounded-full px-2 py-2 bg-[#ECF3FC] items-center h-[4em] w-[4em];
    margin: 0.65em;
    box-shadow: 0px 0px 3px;
  }
  .method-icon:hover, .chain-logo:hover{
    transform: scale(1.1); /* Example animation, you can customize this */
    box-shadow: 0px 0px 10px darkblue;
  }
</style>