<script lang="ts">
  export let showLoad = false;
  export let loader = 0;

  export let color = '0ea5e9'; // Default color

  document.documentElement.style.setProperty('--loader-color', color);
</script>

{#if showLoad && loader == 0}
<div class="loader">
    <div class="loaderBar" style="background: #{color};"></div>
</div>
{:else if showLoad && loader == 1}
    <div class="loading">
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
      <div class="obj" style="background: #{color};"></div>
    </div>
  {/if}

<style lang="scss">
  .loader {
    width: 50px;
    margin: 0 auto;
    border-radius: 10px;
    border: 4px outset transparent;
    position: relative;
    padding: 2px;
  }
  .loader:before {
    content: '';
    border: 0px solid var(--loader-color, #0ea5e9);
    border-radius: 10px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
  }
  .loader .loaderBar {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: var(--loader-color, #0ea5e9);
    width: 0;
    animation: borealisBar 0.235s linear alternate-reverse infinite;
  }

  @keyframes borealisBar {
    0% {
      left: 0%;
      right: 100%;
      width: 0%;
    }
    10% {
      left: 0%;
      right: 75%;
      width: 25%;
    }
    90% {
      right: 0%;
      left: 75%;
      width: 25%;
    }
    100% {
      left: 100%;
      right: 0%;
      width: 0%;
    }
  }

  .loading {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0px 0px 5px 5px;
  }

  .obj {
    width: 6px;
    height: 0px;
    margin: 0 1px;
    border-radius: 0px;
    animation: loading 0.52s alternate infinite;
  }

  .obj:nth-child(2) {
    animation-delay: 0.15s;
  }
  .obj:nth-child(3) {
    animation-delay: 0.3s;
  }
  .obj:nth-child(4) {
    animation-delay: 0.45s;
  }
  .obj:nth-child(5) {
    animation-delay: 0.6s;
  }
  .obj:nth-child(6) {
    animation-delay: 0.45s;
  }
  .obj:nth-child(7) {
    animation-delay: 0.3s;
  }
  .obj:nth-child(8) {
    animation-delay: 0.15s;
  }

  @keyframes loading {
    0% {
      height: 0;
    }
    50% {
      height: 10px;
      width: 2px;
    }
    100% {
      height: 0;
    }
  }
</style>