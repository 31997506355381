<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { MenuTexts, Locale } from "../Data/Locale";
  import {link} from 'svelte-spa-router'

  const dispatch = createEventDispatcher();

  const onMenuItemClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 50;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }
</script>
<footer class="bg-sky-100 dark:bg-gray-900">
  <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0">
            <a href="https://flowbite.com/" class="items-center w-full flex flex-wrap">
                <img  src="/SkipShift.png" class="h-[5rem] mr-3" alt="SkipShift Logo" />
                <span class="w-full italic text-center   text-sm font-serif whitespace-nowrap dark:text-white">{MenuTexts[$Locale].skip_limits}</span>
            </a>
        </div>
        <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{MenuTexts[$Locale].resources}</h2>
                <ul class="text-gray-500 dark:text-gray-400 font-medium">
                    <li class="mb-4">
                        <button on:click="{(_) => onMenuItemClick("home")}" class="hover:underline">SkipShift</button>
                    </li>
                    <li>
                      <button on:click="{(_) => onMenuItemClick("products")}" class="hover:underline">{MenuTexts[$Locale].features}</button>
                    </li>
                </ul>
            </div>
            <div>
                <ul class="text-gray-500 dark:text-gray-400 font-medium pt-10">
                    <li class="mb-4">
                        <button on:click={(_) => dispatch('showAlerts')} class="hover:underline ">{MenuTexts[$Locale].alerts}</button>
                    </li>
                    <li>
                        <a href="https://rbxdefi.com/en/integrate" target="_blank" class="hover:underline">{MenuTexts[$Locale].integrate}</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{MenuTexts[$Locale].more_info}</h2>
                <div class="grid grid-cols-2 gap-4">
                  <ul class="text-gray-500 dark:text-gray-400 font-medium">
                    <li class="mb-4">
                        <a href="https://docs.rbx.ae/skipshift" target="_blank" class="hover:underline">{MenuTexts[$Locale].documentation}</a>
                    </li>
                    <li class="mb-4">
                      <a class="hover:underline" href="/policy" use:link target="_blank">Privacy Policy</a>
                    </li>
                  </ul>
                  <ul class="text-gray-500 dark:text-gray-400 font-medium pl-8 md:pl-4">
                    <li class="mb-4">
                      <a class="hover:underline" href="/tos" use:link target="_blank">TOS</a>
                    </li>
                  </ul>
                </div>

            </div>
        </div>
    </div>
   
  </div>
</footer>

<div class="container mx-auto pb-8 footer">
  
  <div class="text-sm hidden md:flex items-center mb-8"></div>

  <div class="flex px-4 items-end justify-between md:hidden mb-8">
    <div class="flex flex-col items-end mb-4 text-neutral-500 text-xs">
      <!-- <div>All rights reserved.</div> -->
      
    </div>
  </div>


  <div class="flex items-center justify-center md:justify-between">
    <span class="md:block hidden text-sm text-white pl-4 text-center">© 2023 <a href="https://flowbite.com/" class="hover:underline">SkipShift™</a>. {MenuTexts[$Locale].all_rights_reserved}
    </span>
    <div class="hidden md:block text-white text-xs">
      <!-- <div>All rights reserved.</div> -->
    </div>
    <div class="flex items-center social-icons">
      <a href="https://rbxtoken.medium.com" target="_blank">
        <i class="fab fa-medium-m"></i>
      </a>
      <a href="mailto:contact@rbx.ae" target="_blank">
        <i class="fas fa-envelope"></i>
      </a>
      <a href="https://rbxdefi.com" target="_blank">
        <i class="fas fa-globe-americas"></i>
      </a>
      <a href="https://twitter.com/skipshiftdefi" target="_blank">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="https://t.me/skipshift_hub" target="_blank">
        <i class="fab fa-telegram"></i>
      </a>
    </div>
  </div>
  <div class="md:hidden text-sm text-white pl-4 pt-3 text-center">© 2023 <a href="https://flowbite.com/" class="hover:underline">SkipShift™</a>. All Rights Reserved.
  </div>
</div>

<style lang="scss">
  .footer {
    z-index: 2;
  }

  .social-icons a {
    @apply ml-2 mr-2 md:mr-0 md:ml-6 text-white transition-colors duration-200 hover:text-blue-500;
  }
</style>