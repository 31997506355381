<script>
    import MailChimp from '../Common/MailChimp.svelte';
    import { PageTexts, Locale } from '../../Data/Locale';
    let email = '';
</script>

<div id="newsletter-banner" tabindex="-1" class=" left-0 z-50 flex justify-between w-full p-4 py-12 border-gray-200 bg-sky-700">
    <div class="flex items-center flex-shrink-0 w-full mx-auto sm:w-auto">
        <form
            action="https://rbx.us14.list-manage.com/subscribe/post?u=f13da7c9b7870a35aeeeb413e&amp;id=c09a606a31&amp;f_id=008d8ce0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_self"
            class="flex flex-col items-center w-full md:flex-row">
            <label for="mce-EMAIL" class="flex-shrink-0 mb-2 mr-auto text-2xl font-medium text-white md:mb-0 md:mr-4 md:m-0"
                >{PageTexts[$Locale].subscribe_title}</label>
            <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="username@email.com"
                class="bg-white border border-gray-300 italic text-gray-900 md:w-64 mb-2 md:mb-0 md:mr-4 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5"
                required />
            <button
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                value="Subscribe"
                class="text-sky-600 bg-white hover:bg-sky-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center"
                >{PageTexts[$Locale].subscribe}</button>
            <div id="mce-responses" class="clear foot">
                <div class="response" id="mce-error-response" style="display: none;" />
                <div class="response" id="mce-success-response" style="display: none;" />
            </div>
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
                /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                <input type="text" name="b_f13da7c9b7870a35aeeeb413e_c09a606a31" tabindex="-1" value="" />
            </div>
        </form>
    </div>
</div>

<style lang="scss">
    input {
        border-radius: 4px;
        border: 1px solid #cfcfcf;
        background: #fff;
        /* box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.16); */
    }
</style>
