import { arrToBufArr, bufArrToArr, bufferToHex, toBuffer, zeros } from "ethereumjs-util";
import BigNumber from 'bignumber.js';
import { writable } from 'svelte/store';
import { RAMP_ENCRYPTION_KEY } from "./LocalStorageItems";
import type { Client, Conversations } from "@xmtp/xmtp-js";
import Contracts from "./Contracts";

export interface IWallet {
  address: string;
  balance: BigNumber;
  decimals: number;
  symbol: string;
}

export const Wallet = writable<IWallet>({
  address: '',
  balance: new BigNumber(0),
  decimals: 1,
  symbol: 'ETH',
});

const loadCryptKey = () => {
  const mem = toBuffer(localStorage.getItem(RAMP_ENCRYPTION_KEY) || zeros(0));
  return bufArrToArr(mem);
};

export const cryptKeyStore = (cryptKey: Uint8Array) => {

  if (localStorage.getItem(RAMP_ENCRYPTION_KEY) === null) localStorage.setItem(RAMP_ENCRYPTION_KEY,  bufferToHex(arrToBufArr(cryptKey)) || zeros(0).toString()); // save also to local storage as a string
  const bufferToHexx = bufferToHex(arrToBufArr(cryptKey));
  const bufferFromUint8Array = toBuffer(cryptKey);
  
  const saved = bufArrToArr(toBuffer(localStorage.getItem(RAMP_ENCRYPTION_KEY) || zeros(0))); // convert to object
  const { subscribe, set, update } = writable(saved); // create the underlying writable store

  return {
    subscribe,
    set: (cryptKey: Uint8Array) => {
      localStorage.setItem(RAMP_ENCRYPTION_KEY, bufferToHex(arrToBufArr(cryptKey))); // save also to local storage as a string
      return set(cryptKey);
    },
    update: (cryptKey: Uint8Array) => {
      localStorage.setItem(RAMP_ENCRYPTION_KEY, cryptKey.toString()); // save also to local storage as a string
      return set(cryptKey);
    },
  };
};

export const ChainId = writable(0);

export const WalletConnected = writable<boolean>(false);

export const WalletSignature = writable<string>();

export const Referrer = writable<string>('0x0000000000000000000000000000000000000000');

export const ChatStore = writable<Conversations>();
export const Xmtp = writable<Client>();

export const CryptKey = cryptKeyStore(loadCryptKey()); // 32 bytes

export const WalletVerified = writable<boolean>(false);
