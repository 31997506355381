<script lang="ts">
    import Nav from './components/Nav.svelte';
    import Footer from './components/Footer.svelte';
    import { onMount } from 'svelte';
    import chains from './Data/Chains';
    import { Chains } from './Data/Chains';
    import { SupportedChains } from './Data/SupportedChains';
    import { ChainId, Referrer } from './Data/Wallet';
    import { Toaster } from 'svelte-sonner'
    import AlertsModal from './components/Common/AlertsModal.svelte';
    import { getRate } from './API/helpers/getCurrencyConversionRate';
    import Router from 'svelte-spa-router';
    import { wrap } from 'svelte-spa-router/wrap';
    import Loadable from './components/Utils/Loadable.svelte';
    import Content from './components/Content.svelte';
    import { isEmbeddedInIFrame } from './API/helpers/iframeProvider';
    import { IFrameEthereumProvider } from './API/helpers/iframeProvider';
    // import 'iframe-resizer/js/iframeResizer.contentWindow.min.js';
    import Contracts from './Data/Contracts';
    import { getUsernameWallet } from './API/helpers/referrals';
    import { Locale, isSupportedLanguage } from './Data/Locale';
    import { PrefillData } from './Data/Prefill';
    import BigNumber from 'bignumber.js';
    import { showDEXModal } from './Data/DEXPreload';
    import { WalletConnected } from './Data/Wallet';
    import DEXModal from './components/Common/DEXModal.svelte';
    
    const routes = {
        '/': Content,
        '/tos': wrap({
            asyncComponent: () => import('./components/Pages/TOS.svelte')
        }),
        '/policy': wrap({
            asyncComponent: () => import('./components/Pages/Policy.svelte')
        }),
        '/integrate': wrap({
            asyncComponent: () => import('./components/Pages/Integrate.svelte')
        }),
        // '/dex': wrap({
        //     asyncComponent: () => import('./components/Pages/DEX.svelte')
        // }),
        '*': wrap({
            asyncComponent: () => import('./components/Content.svelte')
        })
    };

    let widgetLoad = false;
    let iframeResize;
    if (window.location.href.search('widget') >= 0) {
        widgetLoad = true;
        console.log('loading in widget mode...');
        (async () => {
            iframeResize = await import('@iframe-resizer/child');
        })();
    }

    const options = {
        duration: 5000,
        pausable: true,
        theme: {
            '--toastBtnHeight': 'auto',
            '--toastMinHeight': '40px',
            '--toastContainerTop': 'revert',
            '--toastContainerBottom': '1.5rem',
            '--toastBorderRadius': '0.5rem',
            '--toastBtnFont': '200%, Open-sans, inherit',
            '--toastBackground': 'linear-gradient(90deg, #0EA5E9 0%, rgba(14, 165, 233, 0.50) 100%)',
            '--toastWidth': '21rem'
        }
    };

    let showAlertsModal = false;
    let showRPCFixModal = false;

    let activeMenuTextColor: string = '03a9f4';
    let inactiveMenuTextColor: string = 'bae6fd';
    let backgroundColor: string = 'FFFFFF';
    let buttonGradient: string = '0ea5e9';
    let inputBg: string = 'F2F2F2';
    let infoText: string = '6b7280';
    let headerColor: string = 'f3f4f6';

    let onShowAlertsClick = () => {
        showAlertsModal = !showAlertsModal;
    };
    let onShowDEXClick = () => {
        $showDEXModal = !$showDEXModal;
    };
    let onShowRPCFixClick = () => {
        showRPCFixModal = !showRPCFixModal;
    };

    onMount(async () => {
        if (isEmbeddedInIFrame()) {
            const target = window as any;

            const provider = new IFrameEthereumProvider();

            target.web3 = provider;
            target.ethereum = provider;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const referrer = urlParams.get('r');
        console.log('referrer:', urlParams.get('r'));

        const direction = urlParams.get('direction');
        const currency = urlParams.get('currency');
        const asset = urlParams.get('asset');
        const chainId = urlParams.get('chainId');
        const amount = urlParams.get('amount');
        const venue = urlParams.get('venue');

        // console.log('search params:', direction, currency, asset, chainId, amount, venue);
        if(direction && currency && asset && chainId){
            $PrefillData = {
                direction: direction == 'on' ? '1' : '0',
                currency: currency.toUpperCase(),
                asset: asset,
                chainId: +chainId,
                amount: BigNumber(amount || 0),
                venue: venue
            }
        }

        const locale: string | null = urlParams.get('locale');
        //@ts-expect-error
        const userLocale = (navigator?.language || navigator?.userLanguage || '').slice(0, 2);

        if (!!locale && isSupportedLanguage(locale)) $Locale = locale;
        else if (!!userLocale && isSupportedLanguage(userLocale)) $Locale = userLocale;
        else $Locale = 'en';

        $Referrer = (await getUsernameWallet(referrer || '')) || Contracts.ZERO;


        console.log('$Referrer: ', $Referrer);

        if (window.location.href.search('widget') >= 0) {
            widgetLoad = true;
            console.log('loading in widget mode...');

            let bg = urlParams.get('backgroundColor');
            let activeMenuText = urlParams.get('activeMenuTextColor');
            let inactiveMenuText = urlParams.get('inactiveMenuTextColor');
            let button = urlParams.get('buttonGradient');
            let inputBackground = urlParams.get('inputBg');
            let infoTextColor = urlParams.get('infoText');
            let headerBackground = urlParams.get('headerColor');

            if (button) buttonGradient = button;
            if (activeMenuText) activeMenuTextColor = activeMenuText;
            if (inactiveMenuText) inactiveMenuTextColor = inactiveMenuText;
            if (bg) backgroundColor = bg;
            if (inputBackground) inputBg = inputBackground;
            if (infoTextColor) infoText = infoTextColor;
            if (headerBackground) headerColor = headerBackground;
        }

        if (window.location.href.search('mobile_debug') >= 0) {
            createLogPanel();
        }

        // $ChainId = $ChainId ? $ChainId : 1;
        $Chains = chains;
        $SupportedChains = [1, 56, 43114, 369, 109];

        //call getRate once to update the store and cache all exchangeRates
        await getRate('EUR');
    });

    function createLogPanel() {
        const container = document.createElement('div');
        const button = document.createElement('button');
        const logPanel = document.createElement('div');
        const logContainer = document.createElement('div');
        const clearButton = document.createElement('button');
        const scrollToBottomButton = document.createElement('button');
        const toggleWrapButton = document.createElement('button');
        const searchInput = document.createElement('input');

        // Style the button, clear button, scroll to bottom button, and log panel
        button.textContent = 'Logs';
        button.style.position = 'fixed';
        button.style.background = 'skyblue';
        button.style.borderRadius = '10px';
        button.style.bottom = '20px';
        button.style.right = '20px';
        button.style.zIndex = '1000';
        button.style.padding = '5px 10px';

        clearButton.textContent = 'Clear';
        clearButton.style.position = 'fixed';
        clearButton.style.background = 'skyblue';
        clearButton.style.borderRadius = '10px';
        clearButton.style.bottom = '20px';
        clearButton.style.right = '100px';
        clearButton.style.zIndex = '1000';
        clearButton.style.padding = '5px 10px';

        scrollToBottomButton.textContent = '↓';
        scrollToBottomButton.style.position = 'fixed';
        scrollToBottomButton.style.bottom = '10%';
        scrollToBottomButton.style.right = '10%';
        scrollToBottomButton.style.zIndex = '1000';
        scrollToBottomButton.style.display = 'none';
        scrollToBottomButton.style.background = 'skyblue';
        scrollToBottomButton.style.width = '30px';
        scrollToBottomButton.style.height = '30px';
        scrollToBottomButton.style.borderRadius = '50%';
        scrollToBottomButton.style.border = '3px double black';

        toggleWrapButton.textContent = 'Wrap';
        toggleWrapButton.style.position = 'fixed';
        toggleWrapButton.style.bottom = '20px';
        toggleWrapButton.style.right = '180px';
        toggleWrapButton.style.zIndex = '1000';
        toggleWrapButton.style.background = 'skyblue';
        toggleWrapButton.style.width = 'auto';
        toggleWrapButton.style.borderRadius = '10px';
        // toggleWrapButton.style.border = '2px solid black';
        toggleWrapButton.style.padding = '5px 10px';

        searchInput.type = 'text';
        searchInput.placeholder = 'Search logs...';
        searchInput.style.width = '100%';
        searchInput.style.boxSizing = 'border-box';
        searchInput.style.marginTop = '10px';

        logPanel.style.display = 'none';
        logPanel.style.position = 'fixed';
        logPanel.style.bottom = '60px';
        logPanel.style.right = '20px';
        logPanel.style.width = '300px';
        logPanel.style.height = '400px';
        logPanel.style.overflow = 'auto';
        logPanel.style.background = 'black';
        logPanel.style.border = '1px solid black';
        logPanel.style.borderRadius = '10px';
        logPanel.style.zIndex = '999';
        logPanel.style.fontSize = '10px';
        logPanel.style.whiteSpace = 'nowrap';

        logContainer.style.padding = '10px';

        // Append elements to the document body
        document.body.appendChild(button);
        document.body.appendChild(clearButton);
        document.body.appendChild(scrollToBottomButton);
        document.body.appendChild(toggleWrapButton);
        logPanel.appendChild(logContainer);
        logPanel.appendChild(searchInput);
        document.body.appendChild(logPanel);

        // Toggle visibility of the log panel on button click
        button.onclick = function () {
            logPanel.style.display = logPanel.style.display === 'none' ? 'block' : 'none';
            if (logPanel.style.display === 'block') {
                logPanel.scrollTop = logPanel.scrollHeight;
            }
        };

        // Clear the log panel content on clear button click
        clearButton.onclick = function () {
            logContainer.innerHTML = '';
        };

        // Scroll to bottom button click event
        scrollToBottomButton.onclick = function () {
            logPanel.scrollTop = logPanel.scrollHeight;
        };

        // Show/Hide scroll to bottom button based on scroll position
        logPanel.onscroll = function () {
            if (logPanel.scrollTop + logPanel.clientHeight >= logPanel.scrollHeight) {
                scrollToBottomButton.style.display = 'none';
            } else {
                scrollToBottomButton.style.display = 'block';
            }
        };

        // Toggle text wrapping
        toggleWrapButton.onclick = function () {
            logContainer.style.whiteSpace = logContainer.style.whiteSpace === 'nowrap' ? 'normal' : 'nowrap';
        };

        // Filter logs based on search input
        searchInput.oninput = function () {
            const filter = searchInput.value.toLowerCase();
            const logEntries = logContainer.querySelectorAll('div');
            logEntries.forEach((entry) => {
                if (entry.textContent?.toLowerCase().includes(filter)) {
                    entry.style.display = '';
                } else {
                    entry.style.display = 'none';
                }
            });
        };

        // Generate a random color for each log entry
        function getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        // Override console.log to handle objects
        const oldLog = console.log;
        console.log = function (...messages) {
            oldLog.apply(console, messages);
            const formattedMessages = messages.map((m) => {
                const color = getRandomColor();
                if (typeof m === 'object') {
                    try {
                        return `<div style="color:${color};">${JSON.stringify(m, null, 2)}</div>`;
                    } catch (error) {
                        return `<div style="color:${color};">Error in object serialization: ${error.message}</div>`;
                    }
                } else {
                    return `<div style="color:${color};">${m?.toString() || ''}</div>`;
                }
            });
            logContainer.innerHTML += formattedMessages.join('') + '<br>';
            logPanel.scrollTop = logPanel.scrollHeight;
        };

        return {
            log: console.log,
            element: logPanel
        };
    }
</script>

{#if !widgetLoad}
    <div class="bg-gradient-to-b from-blue-500 to-blue-900 md:bg-dark-100 flex flex-col">
        <div id="svelte-toast-container">
            <Toaster visibleToasts={5}  closeButton />
        </div>
        <Nav on:showNotifsModal={onShowAlertsClick} on:showRPCFixModal={onShowRPCFixClick} />
        <Router {routes} />
        <Footer on:showAlerts={onShowAlertsClick} />
        {#if showAlertsModal}
            <AlertsModal on:close={onShowAlertsClick} />
        {/if}
        {#if $showDEXModal && $WalletConnected}
            <DEXModal on:close={onShowDEXClick} />
        {/if}
    </div>
{:else}
    <Loadable
        {headerColor}
        {infoText}
        {inputBg}
        {buttonGradient}
        {inactiveMenuTextColor}
        {activeMenuTextColor}
        {backgroundColor}
        loader={() => import('./components/FrameSkipShift.svelte')} />
{/if}

<style lang="scss">
    :root {
        --toastContainerTop: auto;
        --toastContainerBottom: 1.5rem;
    }

    :global(._toastContainer) {
        right: 0.5rem !important;
    }
    :global(._toastItem) {
        font-size: 10pt;
        width: 20rem;
        background: var(--toastBackground, rgba(66, 66, 66, 0.9));
    }
    :global(._toastBtn) {
        font-size: 200%;
        padding: 0.25rem 1.5rem;
    }
    :global(._toastBar) {
        background: var(--toastBarBackground, white);
    }
</style>
