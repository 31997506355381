declare const ethereum: any;

import { web } from '../Data/Web3Store';
import Web3 from 'web3';
import { ChainId, Wallet, WalletConnected } from '../Data/Wallet';
import { BaseTokens } from '../Data/Tokens';
import { get } from 'svelte/store';
import BigNumber from 'bignumber.js';
import { Chains } from '../Data/Chains';
import { rpcLooper } from './helpers/rpcLooper';
import { SupportedChains } from '../Data/SupportedChains';
import { isEmbeddedInIFrame } from '../API/helpers/iframeProvider';
import { IFrameEthereumProvider } from '../API/helpers/iframeProvider';
import { trackEvent } from './analytics';
import { DEXPreload } from '../Data/DEXPreload';
import Contracts from '../Data/Contracts';
import { RBXTokens } from '../Data/RBXToken';
import EthereumProvider from '@walletconnect/ethereum-provider';
import { PLATFORM_FEE_MULTIPLIER } from '../Data/Constants';
import { getBaseFee } from './P2P';

export const updateWallet = async (chain: number, account: string) => {
    ChainId.set(chain);

    getBaseFee().then((fee) => {
        PLATFORM_FEE_MULTIPLIER.set(fee);
    });

    if(!account){
        WalletConnected.set(false);
        Wallet.set({
            address: '',
            balance: new BigNumber(0),
            decimals: 0,
            symbol: 'UNK'
        });
        return;
    }

    const w3 = get(web);

    const baseToken = BaseTokens.find((t) => t.chainId === chain);

    // try to get balance
    let bal = new BigNumber(0);
    try{
        bal = BigNumber(await w3.eth.getBalance(account));
    } catch(e){
        console.error('Error getting balance: ', e);
    }

    Wallet.set({
        address: account,
        balance: bal,
        symbol: baseToken?.symbol || 'ETH',
        decimals: baseToken?.decimals || 18
    });
};

export const disconnect = async () => {
    const chains = get(Chains);

    Wallet.set({
        address: '',
        balance: new BigNumber(0),
        decimals: 1,
        symbol: 'ETH'
    });

    WalletConnected.set(false);

    const params = new URLSearchParams(location.search);
    const defaultChain = parseInt(params.get('chain_id') || '') || 1;

    ChainId.set(defaultChain);
    const rpcs = chains[defaultChain].rpc || [];
    const defaultWeb = await rpcLooper(defaultChain);
    if (defaultWeb) web.set(defaultWeb);
};

export const connect = async (to?: string) => {
    if (isEmbeddedInIFrame()) {
        console.log('embedded in iframe, using iframe provider');
        console.log('window.ethereum1: ', window.ethereum);
        const target = window as any;

        const provider = new IFrameEthereumProvider();

        target.web3 = provider;
        target.ethereum = provider;
        console.log('window.ethereum2: ', window.ethereum);
    }

    let account;
    let connectWalletError;
    let walletConnected = false;

    if (!!window.ethereum) {
        console.log('window.ethereum found');
        await ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((accountList: any) => {
                console.log('connected wallet, accountList: ', accountList);
                account = accountList[0];
                walletConnected = true;
            })
            .catch((error: any) => {
                console.log('error connecting wallet');
                walletConnected = false;
                connectWalletError = error;
            });

        if (walletConnected) {
            await setConnection(account);
        }
    } else {
        console.log('window.ethereum not found');
        const chainz = get(Chains);

        // create mapping of Chains object to Onboard chains setting
        const replacedKeysInData: any = {};
        Object.keys(chainz).forEach((key: any) => {
            const rpcList = chainz[key]?.rpc;
            if (!rpcList) return;
            const rpcId = Math.round(Math.random() * rpcList.length);
            const rpc = rpcList[rpcId];
            if (!rpc) return;
            const id = key//parseInt(key);
            replacedKeysInData[id] = rpc;
        });

        console.log('replacedKeysInData: ', replacedKeysInData);

        let chnz = get(SupportedChains); //.map((c) => c.toString());

        console.log('chnz: ', chnz);

        const provider = await EthereumProvider.init({
            projectId: '607da897f899868383a293815e9e9775',
            metadata: {
                name: 'SkipShift',
                description: 'Decentralized fiat on/off ramp',
                url: 'https://skipshift.io', // origin must match your domain & subdomain
                icons: ['']
            },
            showQrModal: true,
            optionalChains: chnz as any,

            /*Optional - Add custom RPCs for each supported chain*/
            rpcMap: replacedKeysInData
        });

        //  Enable session (triggers QR Code modal if no wallet is detected)
        await provider.enable();

        if (provider.connected) {
            //  Create Web3
            const w3 = new Web3(provider as any);

            const chainId = await w3.eth.getChainId();
            const addresses = (await provider.request({ method: 'eth_accounts' })) as string[];
            console.log('chainId: ', chainId, ', addresses: ', addresses);
            account = addresses[0];
            console.log('setting wallet to: ', account);

            web.set(w3);
            ChainId.set(chainId);
            addListeners(w3);

            await updateWallet(chainId, account || '');
            WalletConnected.set(true);
        }
    }

    trackEvent('p2p', 'wallet_connected', '', 'success');
};
async function setConnection(account: undefined) {
    const w3 = new Web3(ethereum);
    // console.log('setting wallet to: ', w3);
    web.set(w3);
    const chainId = await w3.eth.getChainId();

    ChainId.set(chainId);
    await updateWallet(chainId, account || '');
    WalletConnected.set(true);

    // Subscribe to accounts change
    addListeners(w3);

    //update dex preload details to match chainId
    DEXPreload.set({
        spending_token: Contracts?.BASE,
        spending_chain_id: chainId,
        receiving_token: RBXTokens[chainId || 1]?.address || '',
        receiving_chain_id: chainId
    });
}

function addListeners(w3: any) {
    const provider = w3.currentProvider as any;

    provider.on('accountsChanged', async (accounts: string[]) => {
        console.log('accountsChanged to: ', accounts);
        const chain = await w3.eth.getChainId();
        const addresses = await provider.request({ method: 'eth_accounts' });
        updateWallet(chain, addresses[0]);
    });

    // Subscribe to chainId change
    provider.on('chainChanged', async (chainId: number) => {
        console.log('chainChanged to: ', chainId);
        const chain = await w3.eth.getChainId();
        const addresses = await provider.request({ method: 'eth_accounts' });
        updateWallet(chain, addresses[0]);
    });

    // Subscribe to session connection
    provider.on('connect', async () => {
        const chain = await w3.eth.getChainId();
        const addresses = await provider.request({ method: 'eth_accounts' });
        console.log('connect event, chain: ', chain, ', addresses: ', addresses);
        updateWallet(chain, addresses[0]);
    });

    // Subscribe to session disconnection
    provider.on('disconnect', async (code: number, reason: string) => {
        // const chain = await w3.eth.getChainId();
        // const addresses = await provider.request({ method: 'eth_accounts' });
        updateWallet(0, '');
    });
}
