<script lang="ts">
    import { createEventDispatcher } from 'svelte/internal';
    import { SkipTexts, Locale } from '../../Data/Locale';
    import { onMount } from 'svelte';

    const dispatch = createEventDispatcher();

    export let title: string = `💡 ${SkipTexts[$Locale].information}`;
    export let content: string =
        'There was an issue with your transaction. Please try again or contact tech support in the SkipShift hub (@SkipShift_hub) on telegram.';
    export let backgroundColor: string = 'FFFFFF';
    export let textColor: string = '6b7280';
    export let headerColor: string = 'f3f4f6';

    let isVisible = true;

    onMount(() => {
        console.log('mounting infobox');
        isVisible = true;
    });
</script>

{#if isVisible}
    <div data-testid="infobox" class="max-w-[75%] m-auto text-sm my-2 md:my-2 rounded-lg shadow-sm border border-gray-200">
        <div class="px-3 py-2 border-b border-gray-200 rounded-t-lg flex" style="background-color: #{headerColor}">
            <h3 class="font-semibold text-gray-900">{@html title}</h3>
            {#if title.search('Error') > -1}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <i class="fas fa-times cursor-pointer ml-auto" on:click={(_) => dispatch('close')}></i>
            {:else}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <i class="fas fa-times cursor-pointer ml-auto" on:click={() => (isVisible = false)}></i>
            {/if}
        </div>
        <div class="px-3 py-2 rounded-b-lg" style="background-color: #{backgroundColor}; color: #{textColor}">
            <p class="max-w-sm whitespace-break-spaces">{@html content}</p>
        </div>
    </div>
{/if}
