<script lang="ts">
    import BigNumber from 'bignumber.js';
    import { createEventDispatcher, onMount, tick } from 'svelte';
    import { blur } from 'svelte/transition';
    import chainSwitcher from '../../API/helpers/chainSwitcher';
    import { Chains } from '../../Data/Chains';
    import { Locale, SkipTexts } from '../../Data/Locale';
    import { P2PDataStore } from '../../Data/P2PDataStore';
    import { SupportedChains } from '../../Data/SupportedChains';
    import type { IToken } from '../../Data/Tokens';
    import { getBalance, getTokenInfo } from '../../Data/Tokens';
    import { ChainId, Wallet, WalletConnected } from '../../Data/Wallet';
    import { web } from '../../Data/Web3Store';
    import LoadBlaster from './LoadBlaster.svelte';
    import Search from './Search.svelte';

    const dispatch = createEventDispatcher();

    export let tokenList: IToken[] = [];
    export let selectedToken: IToken | null = null;
    export let fiat: boolean = false;
    export let showSearch: boolean = false;
    export let switcherToggle: boolean = true;
    export let closeButtonColor: string = '0ea5e9';
    export let selectedCurrency: string = '';
    export let showAvailableOn: boolean = true;
    export let selectedMenuItem: string = 'BUY';
    export let searchAllChains = false;

    let localTokenList: IToken[] = [];

    let searchTerm = '';
    let loading = false;
    let filteredTokenList: IToken[] = [];

    $: performSearch(searchTerm);
    $: availableOnMap = getAvailableOn(tokenList);

    interface ITokenBalanceMap {
        [keys: string]: BigNumber;
    }

    const performSearch = async (v: string) => {
        loading = true;
        if (!tokenList || tokenList?.length === 0) {
            filteredTokenList = [];
            return;
        }
        tokenList = Array.from(new Set(tokenList.map((s) => JSON.stringify(s)))).map((s) => JSON.parse(s));
        if (!v || !v.length) filteredTokenList = [...tokenList];
        else {
            filteredTokenList = tokenList.filter((item) => item?.chainId === $ChainId && (item.symbol?.toLowerCase().indexOf(v.toLowerCase()) || 0) > -1);
        }

        if (v?.length === 42 && filteredTokenList.length === 0) {
            if (searchAllChains) {
                for (const chain of $SupportedChains) {
                    try {
                        const resp = await getTokenInfo($web, v, chain);
                        if (Array.isArray(resp) && resp.length) {
                            filteredTokenList = [...filteredTokenList, ...resp];
                        }
                    } catch (e) {
                        console.error(`couldn't find token by address on chain ${chain}`, e);
                    }
                }
            } else {
                const found = tokenList.find((item) => item?.address?.toLowerCase() === v.toLowerCase());
                if (found) {
                    filteredTokenList = [found];
                } else {
                    try {
                        const resp = await getTokenInfo($web, v, $ChainId);
                        if (!Array.isArray(resp) || !resp.length) {
                            filteredTokenList = [];
                            return;
                        }
                        filteredTokenList = resp;
                    } catch (e) {
                        console.error(`couldn't find token by address`, e);
                        filteredTokenList = [];
                    }
                }
            }
        }
        loading = false;
    };

    const getAvailableOn = (tokens: IToken[]) => {
        const availabilityMap: Record<string, string[]> = {};

        for (const token of tokens) {
            availabilityMap[token.symbol || ''] = [];

            for (const listing of $P2PDataStore.returnData) {
                if(listing.type == "0" && selectedMenuItem == 'BUY') continue;
                if(listing.type == "1" && selectedMenuItem == 'SELL') continue;
                if (listing.asset.symbol === token.symbol && listing.active && selectedCurrency == listing.currency) {
                    for (const paymentMethod of listing.venues as any) {
                        if (!availabilityMap[token.symbol || ''].includes(paymentMethod[0])) {
                            availabilityMap[token.symbol || ''].push(paymentMethod[0]);
                        }
                    }
                }
            }
        }
        return availabilityMap;
    };

    const onTokenSelectClick = async (token: IToken) => {
        if (token.chainId !== $ChainId && switcherToggle && $WalletConnected) {
            await chainSwitcher(token.chainId || $ChainId);
            dispatch('chainSwitched');
        }
        selectedToken = token;
        dispatch('tokenSelected');
        dispatch('close');
    };
    let tokenBalanceMap: ITokenBalanceMap = {};

    onMount(async () => {
        for (const token of localTokenList) {
            try {
                tokenBalanceMap[token.address || ''] = await getBalance($web, $Wallet.address, token.address || '');
            } catch (e) {
                tokenBalanceMap[token.address || ''] = BigNumber(0);
            }
        }

        await tick();
    });

    function toLowercaseHyphenated(str: string) {
        return str.toLowerCase().replace(/\s+/g, '-');
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div on:click={() => dispatch('close')} class="w-screen h-screen bg-transparent fixed top-0 left-0"></div>

<main
    class="list-body absolute md:fixed min-w-[85%] md:min-w-[19em] max-h-[24em] overflow-y-auto z-10"
    id="_token_list_modal"
    in:blur={{ duration: 300 }}
    out:blur={{ duration: 300 }}>
    <div class="flex items-center justify-between">
        <span class="text-black font-light pr-4">{fiat ? SkipTexts[$Locale].currency_list : SkipTexts[$Locale].token_list}:</span>
        <button on:click={() => dispatch('close')}>
            <svg
                class="svg-icon"
                style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                    d="M512 451.6608L403.3536 343.0144a42.606933 42.606933 0 0 0-60.305067 0.034133 42.666667 42.666667 0 0 0-0.034133 60.305067L451.6608 512 343.0144 620.6464a42.606933 42.606933 0 0 0 0.034133 60.305067 42.666667 42.666667 0 0 0 60.305067 0.034133L512 572.3392l108.6464 108.6464a42.606933 42.606933 0 0 0 60.305067-0.034133 42.666667 42.666667 0 0 0 0.034133-60.305067L572.3392 512l108.6464-108.6464a42.606933 42.606933 0 0 0-0.034133-60.305067 42.666667 42.666667 0 0 0-60.305067-0.034133L512 451.6608zM512 1024C229.666133 1024 0 794.333867 0 512S229.666133 0 512 0s512 229.666133 512 512-229.666133 512-512 512z m0-938.666667c-235.264 0-426.666667 191.402667-426.666667 426.666667s191.402667 426.666667 426.666667 426.666667 426.666667-191.402667 426.666667-426.666667-191.402667-426.666667-426.666667-426.666667z"
                    fill="#{closeButtonColor}" />
            </svg>
            <!-- <img src="/icons/close.svg" class="close-button" alt="close button"/> -->
        </button>
    </div>
    {#if !fiat && showSearch}
        <div class="my-2 max-w-[90%]">
            <Search placeholder="Paste token address..." bind:searchTerm fiat={fiat ? true : false} />
            <LoadBlaster showLoad={loading} />
        </div>
    {/if}
    {#if tokenList.length === 0}
        <div class="w-full">
            <div class="spinner m-auto"></div>
            <div class="pt-4 loading-text m-auto text-center text-sm">Loading freshest available liquidity...</div>
        </div>
        <span class="font-normal text-gray-500 italic text-xs pr-4 md:pr-0">
            <LoadBlaster showLoad={true} />
        </span>
    {/if}
    {#each filteredTokenList as token}
        <button
            class="w-full flex items-center pt-2 hover:bg-gray-300 rounded-md"
            on:click={() => {
                onTokenSelectClick(token);
            }}>
            <div class=" flex items-center justify-normal grow">
                <img class="token-logo mr-2 pb-1 pl-1" src={token.logoURI} alt={token.symbol + ' logo'} />
                <div class="pl-2 flex flex-col items-start">
                    <span class="text-xs text-gray-500">{fiat ? token.name : token.name?.slice(0, 12)}</span>
                    <span class="text-[16px] text-black font-semibold">{token.symbol}</span>
                </div>
                {#if showAvailableOn}
                    <div
                        class="flex items-center justify-center availability gap-1 ps-4"
                        data-tooltip="{token.symbol} is available on {availableOnMap[token.symbol || '']}">
                        {#each availableOnMap[token.symbol || ''] as venue, index (venue)}
                            {#if index < 3}
                                <!--im not a fan of the hardcoded filename extension here-->
                                <img class="h-8 w-8 px-1 venue-icon" src={`/payments/${toLowercaseHyphenated(venue)}.png`} alt={venue} />
                            {/if}
                        {/each}
                        {#if availableOnMap[token.symbol || ''].length > 3}
                            <span class="text-sky-500 text-xs">+{availableOnMap[token.symbol || ''].length - 3}</span>
                        {/if}
                    </div>
                {/if}
            </div>

            <span class="mr-4 flex" style="line-height: 100%;">
                {#if !fiat && token?.chainId}
                    <span class="mr-4 text-sm text-gray-500">{$Chains[token.chainId].shortName}</span>
                {/if}
                {#if !fiat && !!tokenBalanceMap[token?.address || '']}
                    <span class:hidden={tokenBalanceMap[token.address || '']?.lte(0)}>{tokenBalanceMap[token.address || '']?.dp(2) || BigNumber(0)}</span>
                    <span class:hidden={!tokenBalanceMap[token.address || '']?.lte(0)}>&nbsp;&nbsp;</span>
                {/if}
            </span>
        </button>
    {/each}
</main>

<style lang="scss">
    .venue-icon {
        border-radius: 80%;
        height: auto;
        max-height: 1.5rem;
        /* margin-left: 0.25rem; */
    }

    .availability[data-tooltip] {
        position: relative;
        border-bottom: 0px dotted #447beb;
        cursor: help;
    }
    .list-body {
        z-index: 1;
        border-radius: 16px;
        padding: 16px 16px 16px 16px;
        border: 1px solid #b6b6b6;
        font-size: 20px;
        background: rgba(255, 255, 255);
        box-shadow:
            0px 1px 0px 0px rgba(0, 0, 0, 0.01),
            0px 8px 4px 0px rgba(0, 0, 0, 0.04),
            0px 24px 16px 0px rgba(0, 0, 0, 0.04),
            0px 32px 24px 0px rgba(0, 0, 0, 0.01);
        border-end-end-radius: 5px;
        border-top-right-radius: 5px;
        overflow-x: hiden;
        overflow: overlay;
    }

    .token-logo {
        width: 2em;
    }

    .spinner {
        width: 2.5em;
        height: 2.5em;
        border: 0.5em solid rgba(0, 0, 0, 0.1);
        border-left-color: #0ea5e9;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>
