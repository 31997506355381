<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import type { IToken } from '../../Data/Tokens';
    import BigNumber from 'bignumber.js';
    import { SkipTexts, Locale } from '../../Data/Locale';
    import LoadBlaster from './LoadBlaster.svelte';
    import { onMount, prevent_default } from 'svelte/internal';
    import { MinMax, getMinMax } from '../../API/utils/aggregateMinMax';
    import { hexToRgba } from '../../API/helpers/hexToRGBA';
    import { PLATFORM_FEE_MULTIPLIER } from '../../Data/Constants';

    export let disabled = false;
    export let message: string;
    export let actionText: string = SkipTexts[$Locale].select_token;
    export let selectedToken: IToken | null = {
        name: '',
        symbol: '',
        address: ''
    };
    export let amount: BigNumber | null = null;
    export let pulse: boolean = false;
    export let loading: boolean = false;
    export let disableInput: boolean = false;
    export let buttonGradient: string = '';
    export let backgroundColor: string = 'F2F2F2';
    export let textColor: string = '6b7280';
    export let notice: boolean = false;
    export let subtractPlatformFee = false;

    let displayAmount: string = amount?.toFixed(5) || '';

    $: {
        if (subtractPlatformFee) {
            if (amount) {
                const adjustedAmount = subtractPlatformFee ? amount.multipliedBy($PLATFORM_FEE_MULTIPLIER) : amount;
                displayAmount = disableInput ? adjustedAmount.toFixed(5) : adjustedAmount.toFixed();
            } else {
                displayAmount = '';
            }
        } else if (disableInput) {
            displayAmount = amount?.toFixed(5) || '';
        }
    }

    $: if (!amount || amount.eq(0)) {
        displayAmount = '';
    }

    // $: console.log('amount:', amount?.toString());

    let select_class: string;
    $: select_class = disabled ? 'text-gray-400 font-semibold pr-2' : 'text-white font-semibold pr-2';

    const dispatch = createEventDispatcher();

    function onClearClick() {
        selectedToken = null;
        amount = null;
        dispatch('clear');
        displayAmount = '';
    }

    let timeout: any;

    function onInput(event: any) {
        console.log('onInput: ', event);
        clearTimeout(timeout);

        let temp = event.target.value.replace(/,/g, '.');

        console.log('event.target.value: ', event.target.value);
        temp = temp.replace(/[^0-9.]/g, '');
        console.log('temp: ', temp);
        if (temp === '') {
            amount = null;
            event.target.value = '';
        } else {
            amount = BigNumber(temp);
            event.target.value = temp;
        }

        timeout = setTimeout(() => {
            dispatch('getNewRate');
        }, 500);
    }

    onMount(() => {
        console.log('bg', backgroundColor);
        if (document.referrer.includes('gempad')) {
            backgroundColor = '121820';
        }
    });
</script>

<div class="inputbox flex items-center" style="background-color: #{backgroundColor}" class:notice>
    <button
        class:disabled
        {disabled}
        on:click={() => dispatch('showTokenList')}
        class="selectedtoken py-2 pl-1 flex min-w-fit rounded-lg bg-gradient-to-r"
        style={disabled ? `` : `background: linear-gradient(90deg, #${buttonGradient} 0%, ${hexToRgba(buttonGradient, 0.5)} 100%);`}
        class:animate-pulse={pulse}>
        {#if selectedToken}
            <img class="w-6 mr-2" src={selectedToken.logoURI} alt="currency" />
            <span class="text-white font-semibold pr-2">{selectedToken.symbol?.slice(0, 4)}</span>
        {:else}
            <span class={select_class}>{actionText}</span>
        {/if}
    </button>
    <div class="flex flex-col items-end">
        {#if loading}
            <span class="font-normal italic text-xs pr-4 md:pr-0" style="color: #{textColor}">
                <LoadBlaster color={buttonGradient} showLoad={loading} />
            </span>
        {:else}
            <span class="font-normal italic text-xs pr-4 md:pr-0" style="color: #{textColor}">{message}</span>
        {/if}

        <div class="flex pr-2 md:pr-0">
            <input
                on:input|preventDefault={(e) => onInput(e)}
                id="amount-input"
                class="pr-2 md:pr-0 w-[80%] md:w-[100%]"
                style="color: #{textColor}; --placeholder-color: #{textColor};"
                bind:value={displayAmount}
                disabled={disableInput}
                placeholder="0"
                inputmode="decimal" />
            {#if selectedToken}
                <span class="pl-2 font-normal" style="color: #{textColor}">{selectedToken.symbol}</span>
                <button on:click={onClearClick} class="ml-2 w-4">
                    <svg
                        class="svg-icon"
                        style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        ><path
                            d="M512 451.6608L403.3536 343.0144a42.606933 42.606933 0 0 0-60.305067 0.034133 42.666667 42.666667 0 0 0-0.034133 60.305067L451.6608 512 343.0144 620.6464a42.606933 42.606933 0 0 0 0.034133 60.305067 42.666667 42.666667 0 0 0 60.305067 0.034133L512 572.3392l108.6464 108.6464a42.606933 42.606933 0 0 0 60.305067-0.034133 42.666667 42.666667 0 0 0 0.034133-60.305067L572.3392 512l108.6464-108.6464a42.606933 42.606933 0 0 0-0.034133-60.305067 42.666667 42.666667 0 0 0-60.305067-0.034133L512 451.6608zM512 1024C229.666133 1024 0 794.333867 0 512S229.666133 0 512 0s512 229.666133 512 512-229.666133 512-512 512z m0-938.666667c-235.264 0-426.666667 191.402667-426.666667 426.666667s191.402667 426.666667 426.666667 426.666667 426.666667-191.402667 426.666667-426.666667-191.402667-426.666667-426.666667-426.666667z"
                            fill="#{buttonGradient}" />
                    </svg>
                </button>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">
    .notice {
        background-color: lightpink !important;
    }
    .inputbox {
        display: flex;
        padding: 16px 16px 6px 16px;
        justify-content: space-between;
        border-radius: 8px;
    }

    input {
        text-align: right;
    }
    .token-content {
        display: flex;
        align-items: left;
    }

    .disabled {
        /* @apply text-gray-400 bg-gray-900; */
        background: #e3e3e3;
        border-color: #d5d5d5;
        border-style: dotted;
        border-width: 2px;
        /* font-style: italic; */
    }

    .disabled span {
        font-weight: 400;
    }

    input::placeholder {
        color: var(--placeholder-color);
    }
</style>
